#hamburger {
  width: 3rem;
  z-index: 1400;
  cursor: pointer;
  outline: none;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
#top-line,
#bottom-line,
#middle-line {
  transform-box: fill-box;
  transform-origin: center;
}
g {
  transition: stroke 0.6s ease-in-out;
} /* 
g.drawer-open {
  stroke: var(--ups-dark-brown);
} */
svg.drawer-open {
  margin-top: 0.5rem;
}
svg.drawer-open #top-line {
  animation: down-rotate 0.6s ease-out both;
}
svg.drawer-open #bottom-line {
  animation: up-rotate 0.6s ease-out both;
}
svg.drawer-open #middle-line {
  animation: hide 0.6s ease-out forwards;
}
svg.drawer-close #top-line {
  animation: down-return 0.6s ease-out both;
}
svg.drawer-close #bottom-line {
  animation: up-return 0.6s ease-out both;
}
svg.drawer-close #middle-line {
  animation: show 0.6s ease-out forwards;
}
@keyframes up-rotate {
  0% {
    animation-timing-function: cubic-bezier(0.16, -0.88, 0.97, 0.53);
    transform: translateY(0px);
  }
  30% {
    transform-origin: center;
    animation-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
    transform: translateY(-10px);
  }
  100% {
    transform-origin: center;
    transform: translateY(-10px) rotate(45deg) scale(0.9);
  }
}
@keyframes up-return {
  0% {
    transform-origin: center;
    transform: translateY(-10px) rotate(45deg) scale(0.9);
  }
  30% {
    transform-origin: center;
    animation-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
    transform: translateY(-10px);
  }
  100% {
    animation-timing-function: cubic-bezier(0.16, -0.88, 0.97, 0.53);
    transform: translateY(0px);
  }
}
@keyframes down-rotate {
  0% {
    animation-timing-function: cubic-bezier(0.16, -0.88, 0.97, 0.53);
    transform: translateY(0px);
  }
  30% {
    transform-origin: center;
    animation-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
    transform: translateY(10px);
  }
  100% {
    transform-origin: center;
    transform: translateY(10px) rotate(-45deg) scale(0.9);
  }
}
@keyframes down-return {
  0% {
    transform-origin: center;
    transform: translateY(10px) rotate(-45deg) scale(0.9);
  }
  30% {
    transform-origin: center;
    animation-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
    transform: translateY(10px);
  }
  100% {
    animation-timing-function: cubic-bezier(0.16, -0.88, 0.97, 0.53);
    transform: translateY(0px);
  }
}
@keyframes hide {
  29% {
    opacity: 1;
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes show {
  29% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
