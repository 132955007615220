:root {
  --ups-brown: #644117; /* rgb(100,65,23) */
  --ups-dark-brown: #351c15; /* rgb(53, 28, 21) */
  --ups-gold: #ffb500;
  --ups-yellow: #febe10;
  --header-height: 3rem;
  --alert: #f44336;
  --warning: #ff9800;
  --info: #2196f3;
  --success: #4caf50;
}
#root {
  height: 100vh;
  overflow: hidden;
}
html {
  height: 100vh;
  font-size: 20px;
  font-family: Exo_2, Tahoma, helvetica, arial, sans-serif;
  margin: 0;
  padding: 0;
  /* background: linear-gradient(110deg, rgb(51, 0, 0) 0%, var(--ups-dark-brown) 40%, var(--ups-brown) 70%, var(--ups-yellow) 100%); */
  background: var(--ups-dark-brown);
  color: #fff;
  background-attachment: fixed;
}
body {
  display: grid;
  grid-gap: 20px;
  grid-template-rows: auto auto 1fr;
  height: calc(100vh - 20px);
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.App {
  text-align: center;
  height: 100vh;
}
.content {
  height: 100%;
}
/* snackbar in app.js */
.MuiSnackbar-root.default .MuiSnackbarContent-root {
  background-color: var(--ups-gold);
}
.MuiSnackbar-root.alert .MuiSnackbarContent-root {
  background-color: var(--alert);
}
.MuiSnackbar-root.warning .MuiSnackbarContent-root {
  background-color: var(--warning);
}
.MuiSnackbar-root.info .MuiSnackbarContent-root {
  background-color: var(--info);
}
.MuiSnackbar-root.success .MuiSnackbarContent-root {
  background-color: var(--success);
}
.MuiSnackbarContent-message {
  overflow: hidden;
  text-overflow: clip;
  white-space: normal;
  max-width: 88%;
}
/* menu drawer */
ul div.menu-subItem {
  padding-left: 1.5rem;
  padding-top: 0;
  padding-bottom: 0;
}
.MuiDrawer-root .MuiDrawer-paper {
  background: var(--ups-gold);
}
ul div.MuiListItem-button:hover {
  background-color: rgba(0, 0, 0, 0.15);
}
/* login modal */
h2#transition-modal-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: normal;
}
div.MuiBackdrop-root {
  background-color: rgba(100, 65, 23, 0.75);
}
.blur-active {
  filter: blur(2px);
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
}
.login-buttons > * {
  margin: 0.25rem 0;
}
.fb-logo {
  width: 1.5rem;
  margin-top: -0.1rem;
  margin-bottom: -0.15rem;
}
.login-buttons {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  width: max-content;
  align-items: center;
  justify-self: center;
}
.logo-container {
  width: 1.5rem;
  padding: 5px 5px 3px 0;
}
button.login-button {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  border-width: 0;
  background: #ffb500 30%;
  color: var(--ups-dark-brown);
  border-radius: 5px;
  white-space: nowrap;
  box-shadow: 1px 1px 0px 1px rgba(0, 0, 0, 0.05);
  transition-property: background-color, box-shadow;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;
  padding-left: 5px;
  padding-right: 5px;
}
button.login-button:focus,
button.login-button:hover {
  box-shadow: -1px 4px 5px 4px rgba(255, 196, 0, 0.35);
  transform: scale(1.05);
}
button.login-button:active {
  background-color: var(--ups-brown);
  box-shadow: none;
  transition-duration: 10ms;
  transform: scale(0.95);
}
.login-text {
  font-size: 1.5rem;
  font-family: "Roboto";
}
/* spinner testing */
.spinner-container {
  position: absolute;
  left: calc(50% - (70vw / 2));
  top: calc(50% - (70vw / 2));
}
.spinner-container > div {
  position: absolute;
  width: 70vw !important;
  height: 70vw !important;
}
.spinner-container > div > img {
  width: 70%;
  margin-top: 20%;
}
.spinner-container > div > svg {
  color: var(--ups-yellow) !important;
}
/* startTimes spinner */
.loading-spinner-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 50px;
}
.map-distance-overlay {
  position: absolute;
  z-index: 1;
  color: black;
  font-weight: bold;
  bottom: 1.25rem;
  background-color: whitesmoke;
  left: 0.25rem;
  padding: 0.25rem;
}
/* startTimes week container */
.big-btn-text {
  font-size: 3rem;
  font-size: 6rem;
  margin: -1.5rem 0 -3rem 0;
}
.add-week-overlay {
  position: absolute;
  right: 0.5rem;
  bottom: 2rem;
  color: var(--ups-gold);
  animation: growInOut 2s infinite;
}
.start-times {
  position: relative;
}
.hoursWeekly-container .tab-right {
  height: 2.4rem;
  width: 1.75rem;
}
.hoursWeekly-container .tab-left {
  height: 2.4rem;
  width: 1.75rem;
}
.tab-right {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
  opacity: 0.85;
  color: var(--ups-gold);
  background: var(--ups-brown);
  border-radius: 0.5rem 0 0 0;
  width: 2.5rem;
  height: 3.25rem;
  text-align: end;
  cursor: pointer;
}
.tab-left {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
  opacity: 0.85;
  color: var(--ups-gold);
  background: var(--ups-brown);
  border-radius: 0 0.5rem 0 0;
  width: 2.5rem;
  height: 3.25rem;
  text-align: start;
  cursor: pointer;
}
.tab-left:hover {
  opacity: 1;
}
.tab-left .smallFont {
  position: absolute;
  font-size: 0.85rem;
  padding-left: 0.05rem;
}
.tab-right .smallFont {
  position: absolute;
  right: 0;
  font-size: 0.85rem;
  padding-right: 0.05rem;
}
.tab-left .padLeft {
  padding-left: 0.15rem;
}
.tab-right .padRight {
  padding-right: 0.15rem;
}
.week-container .tab-right {
  top: 0;
  width: 2rem;
  height: 2.5rem;
  border-radius: 1rem 0 0 1rem;
  right: -0.75rem;
  cursor: pointer;
}
.week-container .tab-left {
  top: 0.75rem;
  width: 2rem;
  height: 2.5rem;
  border-radius: 0 1rem 1rem 0;
  left: 1.25rem;
  cursor: pointer;
}
.share-overlay {
  background: var(--ups-brown);
  color: var(--ups-gold);
  border-radius: 50%;
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 0.5rem;
  padding-right: 0.25rem;
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}
.start-times .share-overlay {
  position: absolute;
  right: calc(50% - 1.35rem);
  bottom: 0.75rem;
  z-index: 1;
}
.hoursWeekly-container .share-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.week {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));
  padding: 0.5rem 0;
  margin: 0;
  position: relative;
}
.week-allShifts {
  display: grid;
  grid-template-rows: repeat(4, 8rem);
  grid-template-columns: 8rem 1fr;
  padding: 0 1rem;
  margin: 0;
  align-items: center;
  gap: 1.5rem;
}
.week-one-shift {
  display: grid;
  grid-template-columns: repeat(7, 8rem);
  gap: 1rem;
  position: relative;
}

.btn-allShift-text {
  font-size: 1.5rem;
}
.btn-text {
  font-size: 0.75rem;
  font-weight: normal;
}
.btn-container {
  display: grid;
  justify-items: center;
}
.start-times {
  height: calc(100% - var(--header-height) - 0.5rem);
}
.start-times-heading {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.start-times-message {
  padding: 10px 20px;
}
.start-times-message-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
p.start-times-message strong {
  color: var(--ups-yellow);
}
.start-times-message-container svg.MuiSvgIcon-root {
  font-size: 15rem;
  fill: var(--ups-yellow);
}
/* shift navbar */
#active-shift-nav {
  transform: scale(1.25);
  border-radius: 6px;
  z-index: 1;
  box-shadow: 3px 0 2px rgba(0, 0, 0, 0.25), -3px 0 2px rgba(0, 0, 0, 0.25);
  border-right: 0;
}
.shift-changer {
  margin-top: 0.5rem;
}
.shift-changer .MuiButtonGroup-groupedContainedHorizontal:not(:last-child) {
  border-right: 1px solid var(--ups-dark-brown);
}
/* Day.js */
#overlay-offset {
  color: var(--warning);
  font-size: 0.5rem;
  position: absolute;
  bottom: -0.25rem;
}
.day-overlay {
  position: relative;
}
.day {
  display: grid;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25), 0 8px 16px -8px rgba(0, 0, 0, 0.3);
}
.today {
  box-shadow: 1px 2px 8px 8px gray !important;
}
.past {
  filter: opacity(0.75) blur(2px);
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
.day-overlay .strikeThroughLine {
  width: 135%;
  height: 135%;
  border-bottom: 3px solid red;
  transform: translateY(-2.75rem) translateX(1.5rem) rotate(45deg);
  -webkit-transform: translateY(-2.75rem) translateX(1.25rem) rotate(45deg);
  position: absolute;
}
.day-overlay .strikeThroughLine.invert {
  transform: translateY(-2.75rem) translateX(-4.5rem) rotate(-45deg);
  -webkit-transform: translateY(-2.75rem) translateX(-4.25rem) rotate(-45deg);
}
.highlight {
  color: #febe10;
}
.holiday-text {
  position: absolute;
  top: 25%;
  color: var(--ups-brown);
}
div.snow-day-text {
  position: absolute;
  margin-top: -3rem;
  margin-left: -1rem;
  color: red;
  font-size: 2rem;
  font-weight: bold;
  transform: rotate(45deg);
  background-color: rgba(0, 0, 0, 0.75);
  width: 10rem;
}
.holiday-text2 {
  position: absolute;
  top: 85%;
  color: red;
}
.snow-day .top-bar {
  filter: grayscale(100%) sepia(100%) hue-rotate(140deg);
}
.holiday .day-overlay {
  filter: grayscale(100%) sepia(100%) hue-rotate(90deg);
}
.past-holiday .day-overlay {
  filter: grayscale(100%) sepia(100%) hue-rotate(90deg);
}
.past-holiday {
  filter: opacity(0.75) blur(2px);
  user-select: none;
}
/* time.holiday div#overlay-offset,
time.past-holiday div#overlay-offset,
time.snow-day div#overlay-offset,
time.past div#overlay-offset {
  display: none;
} */
/* Day.js Calendar element */
time.icon {
  font-family: "Helvetica Neue Bold", arial, helvetica, sans-serif;
  justify-self: center;
  font-size: 1rem; /* change icon size */
  width: 8rem;
  height: 8rem;
  background-color: #fff;
  margin: 10px 0 0 0;
  border-radius: 0.6em;
  overflow: hidden;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform: rotate(0deg) skewY(0deg);
  -webkit-transform: rotate(0deg) skewY(0deg);
  -webkit-transform-origin: 50% 10%;
  transform-origin: 50% 10%;
  box-shadow: 3px 6px 10px 2px #1e2a2d;
  cursor: pointer;
}
.icon * {
  display: block;
  width: 100%;
  font-size: 1.25rem;
  font-weight: bold;
  font-style: normal;
  text-align: center;
}
.top-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  top: 0;
  padding: 0.4rem 0;
  color: var(--ups-dark-brown);
  background-color: var(--ups-gold);
  border-bottom: 1px dashed var(--ups-yellow);
  box-shadow: 0 2px 0 var(--ups-gold);
}
.time {
  position: absolute;
  font-size: 1.8rem;
  color: var(--ups-brown);
  word-spacing: -0.4rem;
}
.date {
  width: 100%;
  font-size: 3rem;
  font-weight: 600;
  letter-spacing: -0.1rem;
  padding-top: 0;
  color: var(--ups-brown);
}
/* clock time picker */
.MuiPickersBasePicker-container .MuiPickersToolbar-toolbar {
  background-color: var(--ups-yellow);
}
.MuiPickersClock-clock .MuiPickersClockPointer-pointer {
  background-color: var(--ups-brown);
}
.MuiPickersClockPointer-pointer .MuiPickersClockPointer-thumb {
  border: 14px solid var(--ups-brown);
}
.MuiPickersClock-clock .MuiPickersClock-pin {
  background-color: var(--ups-brown);
}
button.MuiButton-textPrimary span.MuiButton-label {
  color: var(--ups-dark-brown);
}
/* Hour Tracker */
/* HoursDaily */
.edit-absolute-box {
  position: absolute;
  top: 0;
  margin-top: 1.75rem;
  font-size: 0.8rem;
  color: var(--alert);
}
.absolute-bottom-right {
  position: absolute;
  left: 0;
  bottom: 0;
  background: var(--ups-brown);
  color: var(--ups-gold);
  border-bottom-left-radius: 0.6rem;
  border-top-right-radius: 0.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.25rem;
  margin: 0.125rem;
  box-shadow: -0.125rem 0.125rem 0 0.1rem rgb(53, 28, 21);
  cursor: pointer;
}
.absolute-bottom-right:active {
  box-shadow: 0 0 rgb(40, 21, 16, 1);
  transform: translate(-0.25rem, 0.125rem);
}
.absolute-bottom-right:focus {
  background: rgb(100, 65, 23, 0.95);
}
.clock-times {
  display: grid;
  grid-template-rows: auto;
  justify-content: center;
}
.hour-nav {
  margin-bottom: 0.5rem;
}
.hours-container {
  display: grid;
  grid-template-rows: auto;
  font-family: "Helvetica Neue Bold", arial, helvetica, sans-serif;
  background-color: var(--ups-yellow);
  margin: 0.25rem 0 0 0;
  border-radius: 0.6em;
  overflow: hidden;
  /* box-shadow: 3px 6px 10px 2px #1e2a2d; */
  color: var(--ups-brown);
  justify-items: center;
  margin-bottom: 1rem;
  position: relative;
}
.hours-container .text-container {
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
  font-weight: Bold;
  justify-content: space-around;
  width: 100%;
}
.highlighted-text {
  background-color: var(--ups-gold);
  padding: 0.5rem;
  height: 1.5rem;
  font-weight: Bold;
  color: var(--ups-dark-brown);
  width: 100%;
}
.time-input-box {
  /* margin: 0.75rem; */
  width: 12rem;
  padding: 0.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
div.time-input-box div .MuiFilledInput-underline::before {
  content: none;
}
div.time-input-box div .MuiFilledInput-underline::after {
  content: none;
}
div.time-input-box div .MuiFilledInput-root {
  border-radius: 0.4rem;
}
.warning-text {
  padding: 0.5rem;
  color: crimson;
  font-style: italic;
}
/* back button at bottom */
button.MuiButton-text:nth-child(3) > span:nth-child(1) > a:nth-child(1) {
  color: var(--ups-dark-brown);
}
/* settings */
.MuiList-root {
  background: var(--ups-gold);
  color: var(--ups-dark-brown);
}
.display-counter {
  transform: scale(1.5);
  border-top-left-radius: 25% !important;
  border-bottom-left-radius: 25% !important;
  border-top-right-radius: 25% !important;
  border-bottom-right-radius: 25% !important;
  z-index: 2;
  background: #1e2a2d !important;
  animation: slideUp 2s 1;
}
@keyframes slideUp {
  0% {
    transform: translateY(0.5rem);
  }
  100% {
    transform: translateY(0);
  }
}
/* HoursWeekly */
.day-container-desktop {
  display: flex;
  gap: 2rem;
  background: white;
  border-radius: 0.4rem;
  color: var(--ups-dark-brown);
  align-items: center;
}
.day-container-desktop .title {
  padding: 0.5rem;
  font-weight: bold;
  background: var(--ups-gold);
  border-radius: 0.4rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.hourTracker-time-total {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.25rem;
}
.flexRowCenter {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.total-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
/* edits page */
ul.list {
  list-style-type: none;
  margin: 0.5rem;
  padding: 0;
  display: grid;
  /* 172px denotes min screen width 375px - (10px margin *3(left,right,center gap))/2 columns */
  grid-template-columns: repeat(2, minmax(172px, auto));
  gap: 0.5rem;
  width: calc(100% - 1rem);
  max-height: calc(100% - var(--header-height) - 1rem);
  overflow-y: auto;
  overflow-x: hidden;
  justify-content: space-evenly;
}
.list.noScroll {
  overflow-y: hidden;
}
/* todo: media query for desktop gtc many more columns */
li.card {
  padding: 0.25rem;
  border-radius: 0.25rem;
  background: var(--ups-gold);
  color: var(--ups-dark-brown);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 0.75rem;
  position: relative;
}
h1.card-title {
  margin: 0;
  font-size: 0.9rem;
  width: 100%;
  text-align: center;
}
span.card-userName {
  cursor: pointer;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: start;
}
span.prevValue {
  color: var(--alert);
}
span.newValue {
  color: var(--success);
}
.abs-bottom-right {
  position: absolute;
  right: 0;
  bottom: 0;
  display: grid;
  gap: 1rem;
}
button.standard-button {
  background: var(--ups-brown);
  color: var(--ups-yellow);
  min-width: unset;
  padding: 0.25rem 0.5rem;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}
button.standard-button:hover {
  background-color: var(--ups-dark-brown);
  transform: scale(1.1);
  box-shadow: 3px 6px 10px 2px #1e2a2d;
}
button.standard-button.small:hover {
  transform: scale(1.1) translate(-0.05rem, -0.05rem);
}
button.standard-button.small:hover {
  box-shadow: 1px 2px 10px 1px #1e2a2d80;
}
button.standard-button.red {
  background: var(--alert);
  color: var(--ups-brown);
}
button.standard-button.red:hover {
  background-color: #c1342a;
}
button.standard-button.action {
  background: var(--success);
  color: var(--ups-dark-brown);
}
button.standard-button.action:hover {
  background-color: #1bbe05;
}
.standard-button.small {
  padding: 0.1rem;
}
div.btn-row {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  gap: 1rem;
  margin-top: 1.5rem;
}
div.modal-box {
  position: relative;
  background-color: var(--ups-gold);
  box-shadow: 0 3px 5px 2px rgba(0, 0, 0, 0.4);
  padding: 0.75rem;
  border-radius: 0.4rem;
  color: var(--ups-dark-brown);
}
.modal-box ul {
  padding-left: 1rem;
}
.modal-box li {
  margin-bottom: 0.5rem;
}
.modal-box h2 {
  margin: 0.5rem;
}
.modal-box .sub-text {
  font-size: 0.8rem;
  font-style: italic;
}
.modal-position {
  display: flex;
  align-items: center;
  justify-content: center;
}
button.absolute-top-right {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0.5rem;
}
button.circle {
  border-radius: 50%;
  height: 1.5rem;
  width: 1.5rem;

  color: var(--alert);
  background: var(--ups-brown);
}
/* .modal-close-btn {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0.5rem;
  color: var(--alert);
  background: var(--ups-brown);
  border-radius: 50%;
  height: 1.5rem;
  width: 1.5rem;
}
button.back-btn {
  background: var(--ups-brown);
  color: var(--ups-yellow);
  min-width: unset;
  padding: 0.1rem;
  border-top-left-radius: 0.5rem;
}
button.close-btn {
  background: var(--alert);
  color: var(--ups-yellow);
  min-width: unset;
  padding: 0.1rem;
  border-top-left-radius: 0.5rem;
} */
.prevStartTime {
  position: absolute;
  right: 50%;
  top: 50%;
}
.not-set-warning {
  display: flex;
  align-items: flex-end;
  color: var(--alert);
  margin-top: -0.35rem;
  animation: shake 1.1s 10;
}
.not-set-warning:hover {
  transform: scale(1.25);
}
@keyframes growInOut {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
}
@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  5% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  10% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  15% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  20% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  25% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  30% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  35% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  40% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  45% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  50% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
.hoursWeekly-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: calc(100% - var(--header-height));
  grid-template-rows: 1fr auto 1fr;
  position: relative;
}
.week-container {
  display: grid;
  grid-gap: 0.75rem;
  grid-template-columns: repeat(7, 10rem);
  justify-content: center;
  padding: 2rem;
  margin: 2rem;
  position: relative;
  grid-column: 1/-1;
}
.week-container .day-container {
  display: grid;
  grid-template-rows: auto;
  font-family: "Helvetica Neue Bold", arial, helvetica, sans-serif;
  background-color: var(--ups-yellow);
  border-radius: 0.6em;
  overflow: hidden;
  color: var(--ups-brown);
}
.week-container .day-container .text-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem;
  padding-right: 1.5rem;
  padding-left: 1rem;
  color: var(--ups-brown);
}
.week-container .day-container .text-container-total {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 0.75rem 0 0.75rem 0;
}
.totals-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: fit-content;
  gap: 2rem;
  font-family: "Helvetica Neue Bold", arial, helvetica, sans-serif;
  background-color: #fff;
  margin: 0.5rem 2rem 0 2rem;
  border-radius: 0.6em;
  overflow: hidden;
  box-shadow: 3px 6px 10px 2px #1e2a2d;
  color: var(--ups-brown);
  padding: 0 0.5rem 0 0.5rem;
}
.totals-container-mobile {
  display: none;
}
.big-text {
  font-size: 2rem;
  font-weight: Bold;
  color: var(--ups-brown);
}
.top-date-text {
  background-color: var(--ups-gold);
  padding: 0.5rem;
  height: 1.75rem;
  font-weight: Bold;
  color: var(--ups-dark-brown);
}
.hourTracker-time {
  font-weight: Bold;
}
@media screen and (max-width: 800px) {
  /* :root {
    font-size: 8px;
  } */
  /* .hoursWeekly-container {
    display: block;
  } */
  .week-container {
    display: grid;
    grid-gap: 0.75rem;
    grid-template-columns: repeat(auto-fit, minmax(7rem, 1fr));
    justify-content: center;
    padding: 0;
    margin: 0.5rem 0.5rem 0 0.5rem;
  }
  .clock-times {
    margin: 0 0.5rem;
  }
  .week-container .day-container .text-container {
    padding: 0.25rem;
  }
  .week-container .day-container .text-container-total {
    padding: 0.25rem 0 0 0;
  }
  .top-date-text {
    padding: 0;
  }
  .totals-container.hidden {
    display: none;
  }
  .totals-container-mobile {
    display: flex;
    justify-content: space-evenly;
    background-color: var(--ups-gold);
    color: var(--ups-dark-brown);
    font-weight: bold;
    margin: 0.5rem;
    border-radius: 0.25rem;
  }
}
@media screen and (max-height: 700px) {
  /* iPhone 6 support on start Times*/
  .shift-changer {
    margin-top: 0.35rem;
  }
  .week {
    gap: 10px;
  }
  .week-container {
    grid-gap: 0.5rem;
  }
  .totals-container-mobile {
    margin: 0.25rem 0.5rem 0.25rem 0.5rem;
  }
  .hours-container {
    margin-top: 0.25rem;
    margin-bottom: 0.5rem;
  }
}
@media screen and (max-width: 400px) {
  .day-container .weekly-total {
    font-size: 0.85rem;
  }
}
@media screen and (min-width: 1900px) {
  .week-allShifts {
    gap: 1rem;
  }
}
